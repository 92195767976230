/*
 * Copyright © Bold Brand Commerce Sp. z o.o. All rights reserved.
 * See LICENSE for license details.
 */

import middleware_33439468 from '/home/nde/pim/frontend/modules/@ergonode/core/src/middleware/privilegeRoutingCheck.global.js';

export default (ctx) => {
	middleware_33439468(ctx);
};
