/*
 * Copyright © Bold Brand Commerce Sp. z o.o. All rights reserved.
 * See LICENSE for license details.
 */

import route_623d9ed8 from '/home/nde/pim/frontend/modules/@ergonode/activity-logs/src/config/routes.js';
import route_24a90f38 from '/home/nde/pim/frontend/modules/@ergonode/attributes/src/config/routes.js';
import route_b0e9012c from '/home/nde/pim/frontend/modules/@ergonode/attribute-groups/src/config/routes.js';
import route_3d4d1237 from '/home/nde/pim/frontend/modules/@ergonode/authentication/src/config/routes.js';
import route_4bd18f93 from '/home/nde/pim/frontend/modules/@ergonode/categories/src/config/routes.js';
import route_5f2ede69 from '/home/nde/pim/frontend/modules/@ergonode/category-trees/src/config/routes.js';
import route_bd606ca0 from '/home/nde/pim/frontend/modules/@ergonode/core/src/config/routes.js';
import route_7fd95dd1 from '/home/nde/pim/frontend/modules/@ergonode/dashboard/src/config/routes.js';
import route_168f3b9f from '/home/nde/pim/frontend/modules/@ergonode/channels/src/config/routes.js';
import route_d1ab6ffe from '/home/nde/pim/frontend/modules/@ergonode/media/src/config/routes.js';
import route_fe4472a0 from '/home/nde/pim/frontend/modules/@ergonode/collections/src/config/routes.js';
import route_c2d4daea from '/home/nde/pim/frontend/modules/@ergonode/products/src/config/routes.js';
import route_5e8b8ff1 from '/home/nde/pim/frontend/modules/@ergonode/product-statuses/src/config/routes.js';
import route_00619bea from '/home/nde/pim/frontend/modules/@ergonode/product-templates/src/config/routes.js';
import route_91e0cc62 from '/home/nde/pim/frontend/modules/@ergonode/segments/src/config/routes.js';
import route_42d6eeac from '/home/nde/pim/frontend/modules/@ergonode/status-transitions/src/config/routes.js';
import route_120dca70 from '/home/nde/pim/frontend/modules/@ergonode/roles/src/config/routes.js';
import route_247faf46 from '/home/nde/pim/frontend/modules/@ergonode/users/src/config/routes.js';
import route_4060142a from '/home/nde/pim/frontend/modules/@ergonode/import/src/config/routes.js';
import route_6a8ea310 from '/home/nde/pim/frontend/modules/@ergonode/workflow/src/config/routes.js';

export default {
	route_623d9ed8,
	route_24a90f38,
	route_b0e9012c,
	route_3d4d1237,
	route_4bd18f93,
	route_5f2ede69,
	route_bd606ca0,
	route_7fd95dd1,
	route_168f3b9f,
	route_d1ab6ffe,
	route_fe4472a0,
	route_c2d4daea,
	route_5e8b8ff1,
	route_00619bea,
	route_91e0cc62,
	route_42d6eeac,
	route_120dca70,
	route_247faf46,
	route_4060142a,
	route_6a8ea310,
};
